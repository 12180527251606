import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../images/logo.png';

const Sidebar = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('jwtToken'); // Remove JWT token from localStorage
    navigate('/'); // Redirect to the login page
  };

  const handleProfileClick = () => {
    navigate('/myprofile'); // Redirect to My Profile page
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span><img src={logo} alt="Logo" className="logo" /></span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <li>
            <DashboardIcon className="icon" style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }} />
            <span>Dashboard</span>
          </li>
          <p className="title">BENEFICIARY</p>
          <Link to="/beneficiary" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon"
                style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
              />
              <span>Beneficiary List</span>
            </li>
          </Link>
          <p className="title">IN-PROGRESS</p>
          <Link to="/inprogress" style={{ textDecoration: "none" }} >
            <li>
              <StoreIcon className="icon"  style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}/>
              <span>In-Progress List</span>
            </li>
          </Link>
          <Link to="/finalpreview" style={{ textDecoration: "none" }} >
            <li>
              <StoreIcon className="icon"  style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}/>
              <span>Final Preview</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li onClick={handleProfileClick}> {/* Profile click handler */}
            <AccountCircleOutlinedIcon className="icon" style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "pink",
            }}/>
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppIcon className="icon" style={{
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              color: "crimson",
            }}/>
            <span onClick={handleLogout}>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
